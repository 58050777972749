@mixin menu {
  display: none;
  flex-direction: column;
  $shadow-color: transparentize($background, .2);
  $shadow: 0 10px $shadow-color, -10px 10px $shadow-color, 10px 10px $shadow-color;
  position: absolute;
  background: $background;
  box-shadow: $shadow;
  color: white;
  border: 2px solid;
  margin: 0;
  padding: 10px;
  top: 10px;
  left: 0;
  list-style: none;
  z-index: 99;
}

@mixin header-menu-trigger {
  color: $accent;
  border: 2px solid;
  margin-left: 10px;
  height: 100%;
  padding: 3px 8px;
  margin-bottom: 0 !important;
  position: relative;
  cursor: pointer;
}

.navigation-menu {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 1px;

  @media ($phone) {
    margin: 0;
  }

  &__inner {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      flex: 0 0 auto;
      margin-bottom: 10px;
      white-space: nowrap;

      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }

    @media ($phone) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;

      li {
        margin: 0;
        padding: 5px;
      }
    }
  }

  .spacer {
    flex-grow: 1 !important;
  }
}

.menu {
  display: flex;
  flex-direction: column;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;

  &__trigger {
    margin-right: 0 !important;
    color: $accent;
    user-select: none;
    cursor: pointer;
  }

  &__dropdown {
    @include menu;

    .open & {
      display: flex;
    }

    > li {
      flex: 0 0 auto;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      a {
        display: flex;
        padding: 5px;
      }
    }
  }

  &--desktop {
    @media ($phone) {
      display: none
    }
  }

  &--mobile {
    .menu__trigger {
      @include header-menu-trigger;
      display: none;

      @media ($phone) {
        display: block;
      }
    }

    .menu__dropdown {
      @media ($phone) {
        left: auto;
        right: 0;
      }
    }

    li {
      flex: 0 0 auto;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  &--language-selector {
    .menu__trigger {
      @include header-menu-trigger;

      @media ($phone) {
        display: none;
      }
    }

    .menu__dropdown {
      left: auto;
      right: 0;
    }
  }
}

.svg-icon {
  width: 0.8rem;
  filter: invert(100%) sepia(100%) saturate(0%) brightness(112%) contrast(101%);
  display: inline;
}

.underline {
  border-bottom: white solid 0.8px;
}

.underline a {
  text-decoration: none !important;
}